import { VFC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const Header: VFC = () => {
    const navigate = useNavigate()
    const [mode, setMode] = useState('index')
    const handleMode = () => {
        mode==='index'? setMode('per10'): setMode('index')
        mode==='index'? navigate('per10',{ replace: true}) : navigate('/',{ replace: true}) 
    }

    return (
        <div className='appBar'>
            <ToggleButtonGroup
                value={mode}
                onChange={handleMode}
                aria-label="text formatting"
                size="small"
            >
                <ToggleButton value="index" aria-label="bold">
                    7日移動平均
                </ToggleButton>
                <ToggleButton value="per10" aria-label="bold">
                    人口10万人当たり感染
                </ToggleButton>
            </ToggleButtonGroup>   
        </div>
    )
}
