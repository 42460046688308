// import { BarPlot } from './components/BarPlot'
// import BubblePlot from './components/BubblePlot';
// import { LinePlot } from './components/LinePlot';
import './App.css';
import 'icon_arrow.svg'
import { PrefectureProvider } from './app/context/PrefectureContext';
import { SlectPrefecture } from './components/SlectPrefecture';
import { Test } from './components/Test';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Per10 } from './components/Per10';
import { Header } from './components/Header';


function App() {
  return (
    <div>
      <PrefectureProvider>
        {/* <BarPlot />
        <LinePlot /> 
        <BubblePlot />*/}
        <BrowserRouter>
        <Header />
        <div className="container">
        <div className='item1'>
          <SlectPrefecture />
        </div>
        <div className='item2'>
        <Routes>
          <Route path='/'  element={<Test />} />
          <Route path='/per10'  element={<Per10 />} />
        </Routes>
        </div>
        </div>
        </BrowserRouter>
      </PrefectureProvider>
    </div>
  );
}

export default App;
