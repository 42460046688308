import { VFC, useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { usePrefectureContext } from '../app/context/PrefectureContext'
import { useWindowDimensions } from './useWindowDimensions'

const fetchZenkoku = async () => {
    const res = await fetch('/zenkoku.csv');
    return res;
};
const fetchData = async () => {
    const res = await fetch('/tokyo.csv');
    return res;
};

const fetchData2 = async () => {
    const res2 = await fetch('/osaka.csv');
    return res2;
};

const fetchHokaido = async () => {
    const resHokaido = await fetch('/hokaido.csv');
    return resHokaido;
};

const fetchAichi = async () => {
    const resAichi = await fetch('/aichi.csv');
    return resAichi;
};

const fetchFukuoka = async () => {
    const resFukuoka = await fetch('/fukuoka.csv');
    return resFukuoka;
};

const fetchOkinawa = async () => {
    const resOkinawa = await fetch('/okinawa.csv');
    return resOkinawa;
};


const fetchSaitama = async () => {
    const resSaitama = await fetch('/saitama.csv');
    return resSaitama;
};

export const Test: VFC = () => {
    const [dat, setDat] = useState<Array<number|string>>([])
    const [dat2, setDat2] = useState<Array<number|string>>([])
    const [num, setNum] = useState<Array<number|string>>([])
    const [dataHokaido, setHokaido] = useState<Array<number|string>>([])
    const [dataAichi, setAichi] = useState<Array<number|string>>([])
    const [dataFukuoka, setFukuoka] = useState<Array<number|string>>([])
    const [dataOkinawa, setOkinawa] = useState<Array<number|string>>([])
    const [dataZenkoku, setZenkoku] = useState<Array<number|string>>([])
    const [dataSaitama, setSaitama] = useState<Array<number|string>>([])

    const { width } = useWindowDimensions();


    const {zenkoku, tokyo, osaka, hokaido, aichi, fukuoka, okinawa, saitama, period, mode} = usePrefectureContext()

    useEffect(() => {
        let arrayA:Array<number|string> =[]
        let arrayB:Array<number|string> =[]
        let arrayC:Array<number|string> =[]
        let arrayHokaido:Array<number|string> =[]
        let arrayAichi:Array<number|string> =[]
        let arrayFukuoka:Array<number|string> =[]
        let arrayOkinawa:Array<number|string> =[]
        let arrayZenkoku:Array<number|string> =[]
        let arraySaitama:Array<number|string> =[]
        

        fetchData().then(res=>res.text()).then(res=>res.split(/[\n\r]+/).map(x=>x.split(","))).then(data => {
            for ( var i=0; i < data.length;  i++ ) {
                if(i > 0) {
                    arrayA.push(data[i][0])
                    arrayB.push(data[i][3])
                }
            }
            setNum(arrayA)  
            setDat(arrayB)
        }); 
        fetchData2().then(res2=>res2.text()).then(res2=>res2.split(/[\n\r]+/).map(x=>x.split(","))).then(data2 => {
            for ( var i=0; i < data2.length;  i++ ) {
                if(i > 0) {
                    arrayC.push(data2[i][3])
                }
            } 
            setDat2(arrayC)
        });
        fetchHokaido().then(resHokaido=>resHokaido.text()).then(resHokaido=>resHokaido.split(/[\n\r]+/).map(x=>x.split(","))).then(dataHokaido => {
            for ( var i=0; i < dataHokaido.length;  i++ ) {
                if(i > 0) {
                    arrayHokaido.push(dataHokaido[i][3])
                }
            } 
            setHokaido(arrayHokaido)
        }); 
        fetchAichi().then(resAichi=>resAichi.text()).then(resAichi=>resAichi.split(/[\n\r]+/).map(x=>x.split(","))).then(dataAichi => {
            for ( var i=0; i < dataAichi.length;  i++ ) {
                if(i > 0) {
                    arrayAichi.push(dataAichi[i][3])
                }
            } 
            setAichi(arrayAichi)
        }); 
        fetchFukuoka().then(resFukuoka=>resFukuoka.text()).then(resFukuoka=>resFukuoka.split(/[\n\r]+/).map(x=>x.split(","))).then(dataFukuoka => {
            for ( var i=0; i < dataFukuoka.length;  i++ ) {
                if(i > 0) {
                    arrayFukuoka.push(dataFukuoka[i][3])
                }
            } 
            setFukuoka(arrayFukuoka)
        }); 
        fetchOkinawa().then(resOkinawa=>resOkinawa.text()).then(resOkinawa=>resOkinawa.split(/[\n\r]+/).map(x=>x.split(","))).then(dataOkinawa => {
            for ( var i=0; i < dataOkinawa.length;  i++ ) {
                if(i > 0) {
                    arrayOkinawa.push(dataOkinawa[i][3])
                }
            } 
            setOkinawa(arrayOkinawa)
        });
        fetchSaitama().then(resSaitama=>resSaitama.text()).then(resSaitama=>resSaitama.split(/[\n\r]+/).map(x=>x.split(","))).then(dataSaitama => {
            for ( var i=0; i < dataSaitama.length;  i++ ) {
                if(i > 0) {
                    arraySaitama.push(dataSaitama[i][3])
                }
            } 
            setSaitama(arraySaitama)
        });
        fetchZenkoku().then(resZenkoku=>resZenkoku.text()).then(resZenkoku=>resZenkoku.split(/[\n\r]+/).map(x=>x.split(","))).then(dataZenkoku => {
            for ( var i=0; i < dataZenkoku.length;  i++ ) {
                if(i > 0) {
                    arrayZenkoku.push(dataZenkoku[i][2])
                }
            } 
            setZenkoku(arrayZenkoku)
        }); 
    },[]);
    
    const num2 = num.slice(period)
    const dat12 = dat.slice(period)
    const dat22 = dat2.slice(period)
    const hokaido2 = dataHokaido.slice(period)
    const aichi2 = dataAichi.slice(period)
    const fukuoka2 = dataFukuoka.slice(period)
    const okinawa2 = dataOkinawa.slice(period)
    const saitama2 = dataSaitama.slice(period)
    const zenkoku2 = dataZenkoku.slice(period)

    const lastday = num2[num2.length-2]

    let radius: number
    period >= -57 && period < 0 ? radius = 2 : radius = 0
    
    const datasets_array = []
    if(zenkoku) {
        datasets_array.push(
            {
                label: "全国",
                backgroundColor: "#888888",
                borderColor: "#888888",
                borderWidth: 2,
                pointRadius: radius,
                pointBorderWidth: 0,
                hoverBackgroudCOlor: '#888888',
                data:zenkoku2
            }
        )
    }
    if(hokaido) {
        datasets_array.push(
            {
                label: "北海道",
                backgroundColor: "#e38069",
                borderColor: "#e38069",
                borderWidth:2,
                pointRadius: radius,
                pointBorderWidth: 0,
                hoverBackgroudCOlor: '#3E66DD',
                // showLine: false,
                data:hokaido2
            }
        )
    }
    if(saitama) {
        datasets_array.push(
            {
                label: "埼玉県",
                backgroundColor: "#229955",
                borderColor: "#229955",
                borderWidth:2,
                pointRadius: radius,
                pointBorderWidth: 0,
                hoverBackgroudCOlor: '#3E66DD',
                // showLine: false,
                data:saitama2
            }
        )
    }
    if(tokyo) {
        datasets_array.push(
            {
                label: "東京都",
                backgroundColor: "#3E66DD",
                borderColor: "#3E66DD",
                borderWidth: 2,
                pointRadius: radius,
                pointBorderWidth: 0,
                hoverBackgroudCOlor: '#3E66DD',
                data:dat12
            }
        )
    }
    if(osaka) {
        datasets_array.push(
            {
                label: "大阪府",
                backgroundColor: "#EE0000",
                borderColor: "#EE0000",
                borderWidth:2,
                pointRadius: radius,
                pointBorderWidth: 0,
                hoverBackgroudCOlor: '#3E66DD',
                // showLine: false,
                data:dat22
            }
        )
    }
    if(aichi) {
        datasets_array.push(
            {
                label: "愛知県",
                backgroundColor: "#9922dd",
                borderColor: "#9922dd",
                borderWidth:2,
                pointRadius: radius,
                pointBorderWidth: 0,
                hoverBackgroudCOlor: '#3E66DD',
                // showLine: false,
                data:aichi2
            }
        )
    }
    if(fukuoka) {
        datasets_array.push(
            {
                label: "福岡県",
                backgroundColor: "#aadd22",
                borderColor: "#aadd22",
                borderWidth:2,
                pointRadius: radius,
                pointBorderWidth: 0,
                hoverBackgroudCOlor: '#3E66DD',
                // showLine: false,
                data:fukuoka2
            }
        )
    }
    if(okinawa) {
        datasets_array.push(
            {
                label: "沖縄県",
                backgroundColor: "#dd33aa",
                borderColor: "#dd33aa",
                borderWidth:2,
                pointRadius: radius,
                pointBorderWidth: 0,
                hoverBackgroudCOlor: '#3E66DD',
                // showLine: false,
                data:okinawa2
            }
        )
    }
    
   
    const data = {
        labels: num2,
        datasets: datasets_array
    }

    let optionScale
    let ratio

    mode === 'Log' ? optionScale = 'logarithmic' :optionScale = 'linear'
    width > 460 ? ratio = 2 : ratio = 1
    
    const options = {
        aspectRatio: ratio,
        scales: {
            x: { 
                ticks: {
                        font: {
                            size: 10,
                        },
                        color: '#555',
                },
              },
            y: {
              // type: 'logarithmic',
              type: optionScale,
              ticks: {
                font: {
                    size: 10,
                },
                color: '#555',
                },
            },
          }
    }
 
    return (
        <div>
            <h2>感染者数7日移動平均</h2>
            <p>最終データ： {lastday}(厚労省オープンデータより集計)</p>
            <Line data={data} options={options} /> 
        </div>
    )
}
