import { useContext, useState, createContext } from 'react'

interface PrefectureContextType {
  zenkoku: boolean
  tokyo: boolean
  osaka: boolean
  hokaido: boolean
  aichi: boolean
  fukuoka: boolean
  okinawa: boolean
  saitama: boolean
  period: number
  mode: string
  setZenkoku: React.Dispatch<React.SetStateAction<boolean>>
  setTokyo: React.Dispatch<React.SetStateAction<boolean>>
  setOsaka: React.Dispatch<React.SetStateAction<boolean>>
  setHokaido: React.Dispatch<React.SetStateAction<boolean>>
  setAichi: React.Dispatch<React.SetStateAction<boolean>>
  setFukuoka: React.Dispatch<React.SetStateAction<boolean>>
  setOkinawa: React.Dispatch<React.SetStateAction<boolean>>
  setSaitama: React.Dispatch<React.SetStateAction<boolean>>
  setPeriod: React.Dispatch<React.SetStateAction<number>>
  setMode: React.Dispatch<React.SetStateAction<string>>
}
const PrefectureContext = createContext({} as PrefectureContextType)
export const PrefectureProvider: React.FC = ({ children }) => {
  const [zenkoku, setZenkoku] = useState(true)
  const [tokyo, setTokyo] = useState(false)
  const [osaka, setOsaka] = useState(false)
  const [hokaido, setHokaido] = useState(false)
  const [aichi, setAichi] = useState(false)
  const [fukuoka, setFukuoka] = useState(false)
  const [okinawa, setOkinawa] = useState(false)
  const [saitama, setSaitama] = useState(false)
  const [period, setPeriod] = useState(-57)
  const [mode, setMode] = useState('Linear')

  return (
    <PrefectureContext.Provider value={ {zenkoku,tokyo, osaka, hokaido, aichi, fukuoka, okinawa, saitama, period, mode, setZenkoku, setTokyo, setOsaka, setHokaido, setAichi, setFukuoka, setOkinawa, setSaitama, setPeriod, setMode} }>
      {children}
    </PrefectureContext.Provider>
  )
}
export const usePrefectureContext = (): PrefectureContextType => useContext(PrefectureContext)