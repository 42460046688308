import React, { VFC } from 'react'
import { usePrefectureContext } from '../app/context/PrefectureContext'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const SlectPrefecture: VFC = () => {
    const {zenkoku, setZenkoku} = usePrefectureContext()
    const {tokyo, setTokyo} = usePrefectureContext()
    const {osaka, setOsaka} = usePrefectureContext()
    const {hokaido, setHokaido} = usePrefectureContext()
    const {aichi, setAichi} = usePrefectureContext()
    const {fukuoka, setFukuoka} = usePrefectureContext()
    const {okinawa, setOkinawa} = usePrefectureContext()
    // const {saitama, setSaitama} = usePrefectureContext()
    const {period, setPeriod} = usePrefectureContext()
    const {mode, setMode} = usePrefectureContext()

    //console.log('checkedItems:', checkedItems)
    const handleZenkoku= () => {
        setZenkoku(!zenkoku)
      }
    const handleTokyo= () => {
            setTokyo(!tokyo)
          }
    const handleOsaka= () => {
            setOsaka(!osaka)
          }
    const handleHokaido= () => {
            setHokaido(!hokaido)
          }
    const handleAichi= () => {
            setAichi(!aichi)
          }
    const handleFUkuoka= () => {
            setFukuoka(!fukuoka)
          }
    const handleOkinawa= () => {
            setOkinawa(!okinawa)
          }
    const handlePeriod = (e:any) => {
            setPeriod(e.target.value)
        }
    const handleMode = () => {
            mode==='Linear'? setMode('Log'): setMode('Linear')
        }
    return (
        <div style={{margin: '0 1rem'}}>
            <label htmlFor='zenkoku' style={{cursor:'pointer'}}>
                <input
                    id="zenkoku"
                    type="checkbox"
                    onChange={handleZenkoku}
                    value='全国'
                    checked={zenkoku}
                />
                全国
            </label><br className='smp' />
            <label htmlFor='hokaido' style={{cursor:'pointer'}}>
                <input
                    id="hokaido"
                    type="checkbox"
                    onChange={handleHokaido}
                    value='北海道'
                    checked={hokaido}
                />
                北海道
            </label><br className='smp' />

             <label htmlFor='tokyo' style={{cursor:'pointer'}}>
                <input
                    id="tokyo"
                    type="checkbox"
                    onChange={handleTokyo}
                    value='東京'
                    checked={tokyo}
                />
                東京
            </label><br />
            <label htmlFor='aichi' style={{cursor:'pointer'}}>
                <input
                    id="aichi"
                    type="checkbox"
                    onChange={handleAichi}
                    value='愛知'
                    checked={aichi}
                />
                愛知
            </label><br className='smp' />
            <label htmlFor='osaka' style={{cursor:'pointer'}}>
                <input
                    id="osaka"
                    type="checkbox"
                    onChange={handleOsaka}
                    value='大阪'
                    checked={osaka}
                />
                大阪
            </label><br className='smp' />
            <label htmlFor='fukuoka' style={{cursor:'pointer'}}>
                <input
                    id="fukuoka"
                    type="checkbox"
                    onChange={handleFUkuoka}
                    value='福岡'
                    checked={fukuoka}
                />
                福岡
            </label><br className='smp' />
            <label htmlFor='okinawa' style={{cursor:'pointer'}}>
                <input
                    id="okinawa"
                    type="checkbox"
                    onChange={handleOkinawa}
                    value='沖縄'
                    checked={okinawa}
                />
                沖縄
            </label>
            <div className='bottun-row'>
                <ToggleButtonGroup
                    value={mode}
                    onChange={handleMode}
                    aria-label="text formatting"
                    size="small"
                    style={{marginTop: '14px'}}
                >
                    <ToggleButton value="Linear" aria-label="bold">
                        Linear
                    </ToggleButton>
                    <ToggleButton value="Log" aria-label="bold">
                        Log
                    </ToggleButton>
                </ToggleButtonGroup>
                <p className='kikan'>期間：
                <select value={period} onChange={handlePeriod} className='set'>
                    <option value='-15'>2週間</option>
                    <option value='-22'>3週間</option>
                    <option value='-29'>4週間</option>
                    <option value='-43'>6週間</option>
                    <option value='-57'>8週間</option>
                    <option value='-85'>12週間</option>
                    <option value='-113'>16週間</option>
                    <option value='-142'>20週間</option>
                    <option value='-169'>24週間</option>
                    <option value='-211'>30週間</option>
                    <option value='-253'>36週間</option>
                    <option value='-337'>48週間</option>
                    <option value='-421'>60週間</option>
                    <option value='-505'>72週間</option>
                    <option value='0'>'20/05〜</option>
                </select>
            </p>
           </div>
            {/* 
            <div>
                {tokyo ? ' 東京': null }
                {osaka ? ' 大阪': null }
            </div>
            */}
        </div>
    )
}

